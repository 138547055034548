import React from "react"
import { connect } from "react-redux"

import {
  NotificationsContainer,
  NotificationItem,
} from "./notifications.styled"

const Notifications = ({ notifications }) => {
  return (
    <NotificationsContainer>
      {notifications.data
        .filter(notification => notification.visible === true)
        .map((notification, index) => (
          <NotificationItem key={`${notification.text}${index}`}>
            {notification.text}
          </NotificationItem>
        ))}
    </NotificationsContainer>
  )
}

const mapStateToProps = ({ notifications }) => {
  return { notifications }
}

export default connect(mapStateToProps, null)(Notifications)
