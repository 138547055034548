import styled from "styled-components"
import { device } from "../../utils/screenSizes"

export const NotificationsContainer = styled.nav`
  position: fixed;
  bottom: 10px;
  width: 400px;
  left: calc(50% - 200px);
  z-index: 99999;
`
export const NotificationItem = styled.div`
  background: #5ac36f;
  color: #fff;
  font-size: 0.9em;
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 5px;
  border-radius: 2px;
`
